<template>
  <div>
    <p class="p4" v-if="$props.newRegister">
      <i class="fas fa-info-circle"></i>
      Importante: Será permitida somente uma atividade principal cadastrada.
    </p>
    <p class="p4" v-if="!$props.newRegister">
      <i class="fas fa-info-circle"></i>
      Importante: 1 - O campo especialidade não pode ser alterado. 2 - É
      permitida somente uma atividade principal.
    </p>

    <b-card v-if="!$props.newRegister" class="custom-card my-4">
      <b-form-select
        v-model="selectedOption"
        :options="selectOptions"
        v-on:change="onChangeFormSelect"
        size="lg"
        class=" custom-select-head"
      />
    </b-card>

    <b-card class="custom-card my-4" v-show="showForm()" border-variant="light">
      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Especialidade
        </div>
        <div class="col">
          <b-form-select
            :class="isMobile() ? 'text-align-left' : ''"
            v-model="form.specialtyDescription"
            :state="validateField('specialtyDescription')"
            :options="specialitiesOptions"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          RQE
        </div>
        <div class="col">
          <b-form-input
            v-model.lazy.trim="form.specialtyRqe"
            type="number"
            :state="validateField('specialtyRqe')"
          />
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Área de atuação
        </div>
        <div class="col">
          <b-form-select
            :class="isMobile() ? 'text-align-left' : ''"
            v-model="form.areaDescription"
            :state="validateField('areaDescription')"
            :options="areaOptions"
          />
        </div>
      </div>
      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Divulgar Guia Médico
        </div>
        <div class="col">
          <b-form-checkbox
            :class="isMobile() ? 'check-padding text-align-left' : 'ml-auto'"
            switch
            v-model="form.publishMedicalGuide"
            :state="validateCheckBox('publishMedicalGuide')"
          >
            {{ form.publishMedicalGuide ? "Sim" : "Não" }}
          </b-form-checkbox>
        </div>
      </div>

      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          Especialidade Principal
        </div>
        <div class="col">
          <b-form-checkbox
            :class="isMobile() ? 'check-padding text-align-left' : 'ml-auto'"
            switch
            v-model="form.mainSpecialtyMedical"
            :state="validateCheckBox('mainSpecialtyMedical')"
          >
            {{ form.mainSpecialtyMedical ? "Sim" : "Não" }}
          </b-form-checkbox>
        </div>
      </div>

      <b-form-row v-if="$props.newRegister">
        <b-col>
          <UploadFile
            @updateFile="eventToUpdateFile"
            :multiple="false"
            :description="'Anexar documento para comprovação'"
            :msgErro="'É necessário inserir um anexo.'"
            :state="validateFieldFile('files')"
            :required="true"
            v-model.lazy.trim="form.files"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import UploadFile from "../../../../components/upload-file/UploadFile.vue";
import { isMobile } from "../../../../utils/mobile/mobile";

const DefaultForm = {
  specialtyDescription: null,
  areaDescription: null,
  publishMedicalGuide: false,
  mainSpecialtyMedical: false,
  files: [],
  specialtyRqe: null,
  originalData: null,
};

export default {
  components: { UploadFile },
  props: [
    "medicalSpecialitiesOptions",
    "medicalAreaOptions",
    "loadedFormDataOptions",
    "newRegister",
  ],
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      specialitiesOptions: [],
      areaOptions: [],
      selectedOption: null,
      selectOptions: [],
    };
  },
  methods: {
    isMobile: isMobile,
    initForm() {
      this.setSubmitHandler(this.onSubmit);
      if (!this.$props.newRegister) {
        const loadedFormDataOptions = this.$props.loadedFormDataOptions;
        this.selectOptions = this.buildSelectOptions(loadedFormDataOptions);
      }

      this.specialitiesOptions = this.buildMedicalSpecialitiesOptions(
        this.$props.medicalSpecialitiesOptions
      );

      this.areaOptions = this.buildMedicalAreaOptions(
        this.$props.medicalAreaOptions
      );
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
        formSelected: false,
      };
    },
    showForm() {
      return this.selectedOption || this.$props.newRegister;
    },
    buildSelectOptions(formDataOptions) {
      return [
        { value: null, text: "selecione uma especialidade" },
        ...formDataOptions.map((formOption) => ({
          value: formOption,
          text: formOption.specialtyDescription,
        })),
      ];
    },
    buildMedicalSpecialitiesOptions(specialiatiesOptions) {
      return [
        { value: null, text: "Selecione uma especialidade", disabled: true },
        ...specialiatiesOptions.map((option) => ({
          value: option.specialtyDescription,
          text: option.specialtyDescription,
        })),
      ];
    },
    buildMedicalAreaOptions(medicalAreaOptions) {
      return [
        { value: null, text: "Selecione uma área de atuação", disabled: true },
        ...medicalAreaOptions.map((areaOptions) => ({
          value: areaOptions.description,
          text: areaOptions.description,
        })),
      ];
    },
    onChangeFormSelect(formData) {
      if (!formData) {
        this.form = { ...DefaultForm };
        this.formData = {
          form: { ...this.form },
          onSubmitHandler: this.onSubmit,
          onDeleteHandler: this.onDelete,
          formSelected: false,
        };

        return;
      }
      const publishMedicalGuide =
        formData.publishMedicalGuide &&
        formData.publishMedicalGuide.toLowerCase() == "sim";

      const mainSpecialtyMedical =
        formData.mainSpecialtyMedical &&
        formData.mainSpecialtyMedical.toLowerCase() == "sim";

      formData.areaDescription = formData.areaDescription.trim();

      const form = {
        ...this.form,
        ...formData,
        publishMedicalGuide: publishMedicalGuide,
        mainSpecialtyMedical: mainSpecialtyMedical,
      };

      this.form = form;
      this.form.originalData = { ...this.form };

      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
        formSelected: true,
      };
    },
    validateCheckBox(name) {
      this.validations[name] = true;
      if (!this.formSubmited) return null;
      return true;
    },
    validateFieldFile(name) {
      let value = this.form[name];
      const validation = !!value && value.length > 0;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    validateField(name) {
      let fieldName =
        name == "areaDescription" ? "specialtyDescription" : "areaDescription";
      let valueSecondField = this.form[fieldName];

      let value = this.form[name];

      let validation = !!value;
      if (!validation && !!valueSecondField) {
        validation = true;
      }
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return validation;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    onDelete() {
      return this.selectedOption;
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
.alter-form div.card {
  padding: 0rem;
}
.card-body {
  padding: 0.25rem;
}
select.text-align-left {
  text-align: left;
  padding-left: 0px;
}
.check-padding {
  padding-top: 0.5rem;
}
.form-flex-column {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0rem;
}
.form-flex-row {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem 0rem 0rem;
}
</style>
