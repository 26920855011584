<template>
  <div>
    <AlterFormLayout
      modalId="alter-specilaty-modal"
      formTitle="Alterar Especialidade / Área de atuação"
      deleteButtonText="Solicitar exclusão"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de alteração"
      :onSubmitEvent="onSubmit"
      :onDeleteEvent="onDeleteForm"
      attentionModalId="medical-speciality-attention-modal"
      ref="alter-modal"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <MedicalSpecialityForm
          :loadedFormDataOptions="$props.loadedFormDataOptions"
          :medicalSpecialitiesOptions="$props.medicalSpecialitiesOptions"
          :medicalAreaOptions="$props.medicalAreaOptions"
        />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sua solicitação de alteração da especialidade foi enviada."
      buttonText="Ok, entendi!"
      subdescription="Os dados alterados serão analisados e atualizados após a validação."
      :protocol="this.protocol"
    />
    <attention-modal
      modalId="medical-speciality-attention-modal"
      title="Atenção!"
      submitButtonText="Sim, solicitar a exclusão"
      cancelButtonText="Não, cancelar a solicitação"
      :onAction="onDeleteAttentionModal"
      ref="attetion-modal"
    >
      <template slot="description">
        <div class="justify-content-center mt-5">
          <div>
            <p class="p1 mb-5">
              Todas as informações desta especialidade serão perdidas com esta
              ação, você deve estar ciente que estará deixando de atender
              pacientes na especialidade a ser excluída, inclusive de forma
              particular e/ou através de outros planos e seguros de saúde e
              convênios.
            </p>
          </div>
          <div>
            <p class="p1 mb-5">
              Deseja mesmo solicitar a exclusão desta especialidade?
            </p>
          </div>
        </div>
      </template>
    </attention-modal>
  </div>
</template>

<script>
import MedicalSpecialityForm from "./MedicalSpecialityForm.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";
import {
  deleteMedicalSpeciality,
  updateMedicalSpeciality,
} from "../../../../services/medical-specialities/medical-specialities-services";

export default {
  props: [
    "loadedFormDataOptions",
    "medicalSpecialitiesOptions",
    "medicalAreaOptions",
  ],
  components: {
    AlterFormLayout,
    MedicalSpecialityForm,
    SuccessModal,
    AttentionModal,
  },
  data() {
    return {
      protocol: "",
    };
  },
  methods: {
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },

    async onSubmit(formData) {
      this.protocol = await updateMedicalSpeciality(formData);
      this.showSuccessModal();
    },
    async onDeleteForm(formData) {
      this.protocol = await deleteMedicalSpeciality(formData);
      this.showSuccessModal();
    },
    async onDeleteAttentionModal(action) {
      if (action) {
        const alterModal = this.$refs["alter-modal"];
        alterModal.doDelete();
      }
    },
  },
};
</script>
