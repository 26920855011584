<template>
  <div class="alter-form">
    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Especialidade
      </div>
      <div class="col">
        <b :class="isMobile() ? 'text-align-left' : ''">{{
          specialties.specialtyDescription
        }}</b>
      </div>
    </div>
    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        RQE
      </div>
      <div class="col">
        <b :class="isMobile() ? 'text-align-left' : ''">{{
          specialties.specialtyRqe
        }}</b>
      </div>
    </div>
    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Área de atuação
      </div>
      <div class="col">
        <b>{{ specialties.areaDescription }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Divulgar Guia Médico
      </div>
      <div class="col">
        <b>{{ specialties.publishMedicalGuide }}</b>
      </div>
    </div>

    <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
      <div class="col">
        Especialidade Principal
      </div>
      <div class="col">
        <b>{{ specialties.mainSpecialtyMedical }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "../../../../utils/mobile/mobile";

export default {
  props: ["specialties"],
  methods: {
    isMobile: isMobile,
  },
};
</script>

<style lang="scss" scoped>
.form-flex-column {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0rem;
}
.form-flex-row {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem 0rem 0rem;
}
</style>
