<template>
  <div>
    <AlterFormLayout
      modalId="add-speciality-modal"
      formTitle="Especialidade / Área de atuação"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de nova especialidade"
      :onSubmitEvent="onSubmit"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <MedicalSpecialityForm
          :medicalSpecialitiesOptions="$props.medicalSpecialitiesOptions"
          :medicalAreaOptions="$props.medicalAreaOptions"
          newRegister="true"
        />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sua solicitação de nova especialidade foi enviada."
      buttonText="Ok, entendi!"
      subdescription="Os dados inseridos serão analisados e cadastrados após a validação."
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import MedicalSpecialityForm from "./MedicalSpecialityForm.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { addMedicalSpeciality } from "../../../../services/medical-specialities/medical-specialities-services";

export default {
  props: ["medicalSpecialitiesOptions", "medicalAreaOptions"],
  components: {
    AlterFormLayout,
    MedicalSpecialityForm,
    SuccessModal,
  },
  data() {
    return {
      protocol: "",
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onSubmit(formData) {
      this.protocol = await addMedicalSpeciality(formData, formData.files);
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
  },
};
</script>
