<template>
  <div>
    <b-row class="justify-content-center my-4">
      <p class="p4">
        Confira seus dados e caso houver divergências solicite alteração
        cadastral a qualquer momento.
      </p>
    </b-row>

    <div v-show="!this.loading">
      <AccordionList
        titleKeyName="specialtyDescription"
        :items="this.tableItems"
        :itemsFields="this.tableFields"
        :iconName="this.iconName"
        :getCustomTable="isMobile() ? this.getCustomTable : undefined"
      />

      <MedicalSpecialityAddModal
        newRegister="true"
        :medicalSpecialitiesOptions="this.medicalSpecialitiesOptions"
        :medicalAreaOptions="this.medicalAreaOptions"
      />

      <MedicalSpecialityAlterModal
        :loadedFormDataOptions="this.tableItems"
        :medicalSpecialitiesOptions="this.medicalSpecialitiesOptions"
        :medicalAreaOptions="this.medicalAreaOptions"
      />
      <div>
        <div class="mt-5">
          <b-button
            class="my-3"
            variant="outline-primary"
            v-b-modal:add-speciality-modal
          >
            Adicionar especialidade
          </b-button>

          <b-button
            class="m-3"
            variant="primary"
            v-b-modal:alter-specilaty-modal
            v-if="this.tableItems && this.tableItems.length > 0"
          >
            Alterar ou excluir especialidade
          </b-button>
        </div>
      </div>
    </div>

    <div v-show="this.loading">
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
      <p />
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  getDoctorMedicalSpecialities,
  getMedicalSpecialities,
} from "../../../../services/medical-specialities/medical-specialities-services";
import { getMedicalOperationAreas } from "../../../../services/medical-operation-area/medical-operation-area-services";
import MedicalSpecialityAddModal from "../forms/MedicalSpecialityAddModal.vue";
import MedicalSpecialityAlterModal from "../forms/MedicalSpecialityAlterModal.vue";
import AccordionList from "../../../../components/accordion-list/AccordionList.vue";
import SpecialtiesDetails from "./SpecialtiesDetails.vue";
import { isMobile } from "../../../../utils/mobile/mobile";

export default {
  name: "specialities-information",
  components: {
    MedicalSpecialityAddModal,
    MedicalSpecialityAlterModal,
    AccordionList,
  },
  mounted() {
    const crm = this.$store.getters.getUserName;
    this.getDoctorMedicalSpecialities(crm);
    this.getAllMedicalSpecialities();
    this.getMedicalAreaOptions();
  },
  data() {
    return {
      tableFields: [
        {
          key: "specialtyDescription",
          label: "Especialidade",
          tdClass: "text-right",
        },
        {
          key: "specialtyRqe",
          label: "RQE",
          tdClass: "text-right",
        },
        {
          key: "areaDescription",
          label: "Área de Atuação",
          tdClass: "text-right",
        },
        {
          key: "publishMedicalGuide",
          label: "Divulgar Guia Médico",
          tdClass: "text-right",
        },
        {
          key: "mainSpecialtyMedical",
          label: "Especialidade Principal",
          tdClass: "text-right",
        },
      ],
      tableItems: [],
      medicalSpecialitiesOptions: [],
      medicalAreaOptions: [],
      loading: true,
      iconName: [
        { icon: "iconMedicalGuide", tooltip: "Divulgar Guia Médico" },
        { icon: "iconSpecialtyMedical", tooltip: "Especialidade Principal" },
      ],
    };
  },
  methods: {
    isMobile: isMobile,
    async getDoctorMedicalSpecialities(getfromLoginCrm) {
      try {
        this.loading = true;
        var medicalEspecialities = await getDoctorMedicalSpecialities(
          getfromLoginCrm
        );
        this.loading = false;

        this.tableItems = medicalEspecialities.map((me) => {
          me.publishMedicalGuide =
            me.publishMedicalGuide.toLowerCase() == "s" ? "Sim" : "Não";
          me.mainSpecialtyMedical =
            me.mainSpecialtyMedical.toLowerCase() == "s" ? "Sim" : "Não";

          me.iconMedicalGuide =
            me.publishMedicalGuide.toLowerCase() == "sim"
              ? "notebook-check-outline.svg"
              : null;
          me.iconSpecialtyMedical =
            me.mainSpecialtyMedical.toLowerCase() == "sim"
              ? "heart-pulse.svg"
              : null;

          return me;
        });
      } catch (error) {
        this.loading = false;
        console.error("getMedicalSpecialities", error);
      }
    },
    async getAllMedicalSpecialities() {
      try {
        this.loading = true;

        const medicalEspecialitiesOptions = await getMedicalSpecialities();

        this.loading = false;
        this.medicalSpecialitiesOptions = medicalEspecialitiesOptions;
      } catch (error) {
        this.loading = false;
        console.error("getMedicalSpecialities", error);
      }
    },
    getCustomTable(specialties) {
      return <SpecialtiesDetails specialties={specialties} />;
    },
    async getMedicalAreaOptions() {
      try {
        this.loading = true;

        const medicalAreaOptions = await getMedicalOperationAreas();

        this.loading = false;
        this.medicalAreaOptions = medicalAreaOptions;
      } catch (error) {
        this.loading = false;
        console.error("getMedicalSpecialities", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
